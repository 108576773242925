// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("trix");
require("@rails/actiontext");
require("channels");
import "bootstrap";
import AOS from "aos";
import landKitNavbarInit from "../src/frontend/landkit/navbar";
import landKitDropdownInit from "../src/frontend/landkit/dropdown";
require("@fancyapps/fancybox");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "../stylesheets/frontend";

jQuery(function () {
  AOS.init({ once: true });
  landKitNavbarInit();
  landKitDropdownInit();

  $().fancybox({
    selector: ".attachment--preview a",

    caption: function (_instance, _item) {
      return $(this).parent().find("figcaption").text();
    },
  });
});
